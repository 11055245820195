<template>
  <div class="view pa24">
    <el-button
        class="mr10"
        type="primary"
        @click="add"
    >+ 添加楼层</el-button>
    <commonTable
        :tableData="tableData"
        :loading="loading"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        :currentPage="currentPage"
        :total="total"
    >
      <template v-slot:table>
        <el-table-column type="selection" width="50"/>
        <el-table-column prop="storeyName" align="center" label="楼层名称" show-overflow-tooltip/>
        <el-table-column prop="createTime" align="center" label="创建时间" show-overflow-tooltip/>
        <el-table-column prop="storeyPattern" align="center" label="楼层样式" show-overflow-tooltip/>
        <el-table-column align="center" label="排序">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="数值越大，排序越靠前" placement="right-start">
              <el-input v-model="scope.row.sort" min="0" @input="scope.row.sort=scope.row.sort.replace(/^0(0+)|[^\d]+/g,'')"  placeholder="数值越大，排序越靠前" @change="saveSort(scope.row)"/>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column align="center" label="状态" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-switch
                @change="saveStatus(scope.row)"
                v-model="scope.row.status"
                active-color="#51CDCB"
                inactive-color="#dadde5">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="200">
          <template slot-scope="scope">
            <el-button
                type="text"
                @click="
                edit(scope.row, true);
                bEdit = false;
                editUpdate = true
              "
            >编辑</el-button
            >
            <customPopconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                @confirm="del(scope.row.storeyId)"
                icon="el-icon-info"
                icon-color="red"
                title="确定要删除吗？">
              <el-button
                  slot="reference"
                  class="ml10"
                  style="color: #fd634e"
                  type="text"
              >删除</el-button
              >
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { mapState } from 'vuex';
import customPopconfirm from "@/components/common/customPopconfirm";
import {addAssTempaltePage,queryAssWebsiteStoreyByPage,updateAssWebsiteStorey,delAssWebsiteStorey} from "@/api/website";
let ac;
export default {
  name:'floorManagement',
  data() {
    return {
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      goodsList: [],
      goodsListCurrentPage: 1, //当前页
      goodsListPageSize: 10, //显示条数
      goodsListLoading: false, //表格加载
      goodsListTotal: 0, //总条数
      centerDialogVisible: false,
      dialogDetailVisible: false,
      bEdit: false,
      bdetermine: true,
      editUpdate:true,
      isUpdate:true,
      fileList: [],
      userInfo: this.$store.state.loginRoot.userInfo,
      ruleForm: {
        title: "",
        sort: "",
        isState: 0,
      },
      rules: {
        title: [{ required: true, message: "请输入名称", trigger: "blur" }],
        sort: [{ required: true, message: "请输入排序", trigger: "blur" }],
        isState: [{ required: true, message: "请选择状态", trigger: "blur" }],
      },
      jurisdictionA:'',
      jurisdictionB:'',
      storeyId:'',
      ossUrl: this.$store.state.ossUrl,
    };
  },
  components: {
    commonTable,
    customPopconfirm
  },
  computed: {
    ...mapState(['routerArr']),
  },
  mounted(){
    this.storeyId = this.$route.query.storeyId
    this.queryPage();
  },

  methods: {
    add(){
      this.$router.push({
        path: `/addfloor`,
        query: {
          pageId:this.$route.query.pageId
        },
      });
    },
    async queryPage() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        associationId: localStorage.getItem("associationId"),
        pageId:this.$route.query.pageId
      };
      try {
        this.loading = true;
        const result = await queryAssWebsiteStoreyByPage(data);
        this.loading = false;
        const { total, list } = result.data.pageInfo;
        for (let i in list) {
          if (list[i].status == 0) {
            list[i].status = true
          }else {
            list[i].status = false
          }
          if (list[i].createTime) {
            list[i].createTime = this.renderTime(list[i].createTime)
          }
          list[i].storeyPattern = this.$common.templateStyle(list[i].storeyPattern)
        }
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.tableData = []
        this.loading = false;
      }
    },
    renderTime(date) {
      var dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryPage();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryPage();
    },
    /**@method 编辑
     * @param {Object} val - 当前点击行的值
     */
    edit(val) {
      this.storeyId = val.storeyId
      this.$router.push({
        path: `/addfloor`,
        query: {
          pageId:this.$route.query.pageId,
          storeyId:val.storeyId
        },
      });
    },
    /**@method 添加 */
    determine() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            let data = {
              associationId: localStorage.getItem("associationId"),
              pageName:this.ruleForm.title,
              sort:this.ruleForm.sort,
              status:this.ruleForm.isState,
            };
            this.$message({
              message: "正在保存",
            });
            if (this.editUpdate) {
              data.storeyId = this.storeyId
              await updateAssWebsiteStorey(data);
            } else {
              await addAssTempaltePage(data);
            }
            this.centerDialogVisible = false;
            this.$message.closeAll();
            this.$message({
              message: "保存成功",
              type: "success",
            });
            await this.queryPage();
          } catch (error) {
            this.$message.closeAll();
            this.$message({
              message: error.message,
              type: "error",
            });
            this.centerDialogVisible = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /**@method 删除
     * @param {String} val - 文章id
     */
    async del(val) {
      try {
        await delAssWebsiteStorey({ storeyId: val });
        await this.queryPage();
      } catch (error) {
        console.log(error);
      }
    },
    async saveSort(row){
      if(row && row.sort != ""){
        let ajax = {
          storeyId:row.storeyId,
          sort:row.sort
        }
        const result = await updateAssWebsiteStorey(ajax);
        this.$message({
          type: "success",
          message: "保存成功",
        });
        await this.queryPage();
      }else{
        this.$message({
          type: "error",
          message: "排序不能为空",
        });
      }
    },
    async saveStatus(row){
      if(row){
        let ajax = {
          storeyId:row.storeyId,
          status:row.status?0:1
        }
        const result = await updateAssWebsiteStorey(ajax);
        this.$message({
          type: "success",
          message: "保存成功",
        });
        await this.queryPage();
      }else{
        this.$message({
          type: "error",
          message: "不能为空",
        });
      }
    },
    /**@method 模态框关闭的回调 */
    close() {
      (this.ruleForm = {
        title: "",
        sort: "",
        isState: 0,
      })
      this.centerDialogVisible = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  //line-height: 175px;
  text-align: center;
}
/deep/ .avatar-coverImg .el-upload--text {
  width: 100px;
  height: 100px;
}
/deep/ .avatar-coverImg .avatar-uploader-icon {
  line-height: 100px;
}
.select-goods-list {
  margin-bottom: 20px;
  border-radius: 40px;
  box-sizing: border-box;
  padding: 0 20px;
  background: #ffffff;
  transition: all 0.4s;
}
.select-goods-list:hover {
  background: #f5f5f6;
  transition: all 0.4s;
  cursor: pointer;
}
/deep/ .disabled .el-upload--picture-card {
  display: none;
}
</style>



